import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ReservedAngels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      angels: this.props.angels,
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.angels !== this.props.angels) {
      this.setState({
        angels: this.props.angels,
      });
    }
  };

  handleAdoption = () => {
    this.props.adoptReservedAngels();
    this.props.history.push("/reservedTags");
  };

  render() {
    return (
      <React.Fragment>
        <div className="pt-8">
          <h2 className="text-2xl font-semibold leading-tight text-gs-navy">
            Your Selected Tags
          </h2>
        </div>
        <div
          className="-mx-4 sm:-mx-8 px-4 sm:px-8 pt-4 pb-2 overflow-x-auto"
          style={{ height: "27rem" }}
        >
          <div className="inline-block min-w-full shadow rounded-lg overflow-y-auto">
            <table
              className="min-w-full leading-normal table-auto"
              id="reservedAngelTable"
            >
              <thead>
                <tr>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Gift Request</th>
                  <th>Size/Notes</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.angels.map((angel) => (
                  <tr key={angel["Gift ID"]}>
                    <td>{angel["Age"]}</td>
                    <td>{angel["Gender"]}</td>
                    <td>{angel["Gift Request"]}</td>
                    <td>{(angel["Size"]? angel["Size"] : "") + " " + (angel["Notes"]? angel["Notes"] : "")}</td>

                    <td>
                      <svg
                        aria-hidden="true"
                        data-prefix="far"
                        data-icon="cross-alt"
                        className="w-4 text-gs-navy hover:text-blue-900 m-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        onClick={(e) =>
                          this.props.onRemoveReserved(
                            e,
                            angel["Gift ID"],
                            false
                          )
                        }
                        onKeyUp={(ev) => { (ev.key === 'Enter') && this.props.onRemoveReserved(
                          ev,
                          angel["Gift ID"],
                          false
                        )}}
                        role="button"
                        tabIndex="0"
                        aria-label="Remove Selected"
                      >
                        <path fill="currentColor"
                              d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="w-full" onClick={this.handleAdoption}>
            <span className="ml-2 mt-5px">Reserve Selected Tags</span>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ReservedAngels);
