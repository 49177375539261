import { GoogleSpreadsheet } from "google-spreadsheet";

// Config variables
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_CLIENT_EMAIL;
const SPREADSHEET_ID = process.env.REACT_APP_GOOGLE_SPREADSHEET_ID;
const AGENCIES_SHEET_ID = process.env.REACT_APP_GOOGLE_SHEET_AGENCIES_ID;
const ANGELS_SHEET_ID = process.env.REACT_APP_GOOGLE_SHEET_TAGS_ID;
const USERS_SHEET_ID = process.env.REACT_APP_GOOGLE_SHEET_USERS_ID;
const ADOPTIONS_SHEET_ID = process.env.REACT_APP_GOOGLE_SHEET_RESERVATIONS_ID;

const PRIVATE_KEY =
  "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCmfXXz2FIUal1o\n/bErz4hi3wN7iI8Gt36Ag90lmufniJ0s2bcOgdOThljA/Fx/GPfk1qm7qa2l+++s\nvQ1Kz7QeqfW0B4UlMimxj5zjOrVQQ9/kw0+OjkKKTZW4PaTCIQkx/AlktMrBwfzN\n7x/iozKHtIkuNDbabQmQQY94KLKE2WqZnsOF8esDbEv/tWdyqx3Z9fePPtWmy0AT\nzQrU+NmKgMAXRZgkXGh2etjwfD6ybkMAnlwOSGHB6xLx6MHvue74NvAbR8PxZ2gg\n15aq8wM6Wvk84zRmoiUumO60pQP60mJDx9rxNUJ43NljuhfsWY4Ys+U7L34+T2+b\nhvMk2lC/AgMBAAECggEAA8fRNVvMpVey+PmFxpERr5kRJa14qKDjDP0G47M29TbQ\nxt4cvjDBgqs9ebML+1Da/tscWJK7oDYZKlV9UfJnIdPJ11rdBvayzqw3APaU0Rac\nJMAwnsk+hpBzcrm884biLryNOWMHlaBs4qo9kZ0TaSnMJvya4EPd6zop4q3IkU6r\nL0QtfAg6XiEYTEYGQ1SP4/ztdPL+wTBmR3aEQvgxeIRkirDMaXOnjX5maQEXzodB\nhj0RAPeJF0BAd64cVCZLrRnac96DCopDtkUBLYB8PxIEIfUvU6//nw487GPLDFKA\nl9jVokarECPYWUrBFsxRMcAh0Yqr9fkiO/DKygNZJQKBgQDWSnp/57uBGU5BImFb\nqVLkqOb1jHklYy9UKYB0dpzF77TiqBlWsqoUj9JVAovHVxxKrYTCnLQNxFJBmQja\nwlrQWfGZGuA7CNiS3eKBNeJiSM/B8SDOMyxraabs+KtJTizEiDG2gcTbfmeSiD/G\nQIp/6Dd1gwHalXjSNlTR5pI7qwKBgQDG5TLfJhMjLhK65WpRG77ThFlyP7SfhHbT\nxQFRKPuU33xolk6dNFR87o0CEWeCsH1lnjOP3KhNNCfRYzYV3Zan6TX2kpogDQ92\npIPQtDoZ3dv5kpQawwswmYiWp/vGhjDiSA5rO5RMAOy/y4xCNWpO4ymfryYR34aP\nOB7+5pVLPQKBgFyTtpJ1cZsXbKhVCwyHfUlN8uGYO2o8jGDmwRkPeTFGGrZk+vVq\noHHbpHmXbP3Ek3NrvVQ6UMs1yneOfrXLi2JByU9kvgSjELV6aHV76v6dCCx4WLzc\nfM82yKfN0moL/Ok3EYsQSxSsamByvmKO1Ybmsrbwzyc4tgbS9BfubbSbAoGBALe/\nk4n44QFFVWR5AgnAukErKn+UP0iH1ke634adeB+HgKYXBrXRXKS2tmrMrXZdmtvA\nlh44WvxITOjy4Uq+Fqs1lvcfZWhq6HhRcL3lYy/2eX1OX6mBu9rAXSHiO4ai/pJu\nw3Np7Y1x5Ucjw4Z/VqFe2FuAMomcmfHChVA0soR5AoGALQExkRxz4rTkhNq41fm8\noFQV8NMEYKt2YWSx7OYNBEXyoYLDFLfPvfDcZ0hKu+Q+4F5PM6EORG4JCmdWMx4y\nqTg0f+vfiKLAvdtLSsmEvKjbYe/2yiVirlhQKn7YnCSdGRKbDZWhI6QAMrDs90J9\nFRrCxsmHUNpHAME8Td3aido=\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

export const initializeSheetConnection = async () => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const appendSpreadSheetRow = async (sheetId, row) => {
  try {
    await doc.loadInfo();

    const sheet = doc.sheetsById[sheetId];
    await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const appendSpreadSheetRows = async (sheetId, rows) => {
  try {
    // loads document properties and worksheets
    await doc.loadInfo();
    const sheet = doc.sheetsById[sheetId];
    await sheet.addRows(rows);
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const getSpreadsheetRows = async (sheetId) => {
  try {
    await doc.loadInfo();

    const sheet = doc.sheetsById[sheetId];

    return await sheet.getRows();
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const getUnAdoptedAngels = async () => {
  try {
    await doc.loadInfo();

    const angelSheet = doc.sheetsById[ANGELS_SHEET_ID];

    const allAngels = await angelSheet.getRows();

    const adoptedAngelSheet = doc.sheetsById[ADOPTIONS_SHEET_ID];

    const adoptedAngels = await adoptedAngelSheet.getRows().then((adoptions) => {
      return adoptions.map((adoption) => adoption["Gift ID"]);
    });
    return allAngels.filter((angel) => !adoptedAngels.includes(angel["Gift ID"]));
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const getAdoptedAngels = async (userName) => {
  try {
    await doc.loadInfo();

    const adoptionSheet = doc.sheetsById[ADOPTIONS_SHEET_ID];

    const userAdoptions = await adoptionSheet.getRows().then((allAdoptions) => {
      return allAdoptions
        .filter((adoption) => adoption.UserName === userName)
        .map((eachAdoption) => eachAdoption["Gift ID"]);
    });

    const angelSheet = doc.sheetsById[ANGELS_SHEET_ID];

    return await angelSheet.getRows().then((allAngels) => {
      return allAngels.filter((angel) => userAdoptions.includes(angel["Gift ID"]));
    });
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const deleteAdoptedAngel = async (username, angelId) => {
  try {
    await doc.loadInfo();

    const adoptionsSheet = doc.sheetsById[ADOPTIONS_SHEET_ID];
    const allAdoptions = await adoptionsSheet.getRows().then((result) => {
      return result;
    });

    let tobeDeleted = allAdoptions.find(
      (adoption) => adoption["UserName"] === username && adoption["Gift ID"] === angelId
    );
    await tobeDeleted.delete();
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const getAngels = async (userName) => {
  try {
    await doc.loadInfo();

    const agencySheet = doc.sheetsById[AGENCIES_SHEET_ID];
    const allAgencies = await agencySheet.getRows().then((result) => {
      return result;
    });

    const angelSheet = doc.sheetsById[ANGELS_SHEET_ID];
    const allAngels = await angelSheet.getRows().then((result) => {
      let angelsWithAddresses = result.map((angel) => {
        angel.Address = allAgencies.find(
          (agency) => agency["Agency ID"] === angel["Agency ID"]
        ).Address;
        return angel;
      });
      return angelsWithAddresses;
    });

    const adoptionSheet = doc.sheetsById[ADOPTIONS_SHEET_ID];

    const allAdoptedAngels = await adoptionSheet.getRows().then((allAdoptions) => {
      return allAdoptions;
    });

    let userAdoptions = allAdoptedAngels.filter(
      (adoption) => adoption.UserName === userName
    );

    let userAdoptedAngles = userAdoptions.map((eachAdoption) => {
      return allAngels.filter((angel) => angel["Gift ID"] === eachAdoption["Gift ID"]);
    });

    let adoptedAngelIds = allAdoptedAngels.map((angel) => angel["Gift ID"]);

    let unAdoptedAngels = allAngels.filter((angel) => {
      return adoptedAngelIds.includes(angel["Gift ID"]) ? false : true;
    });

    let userAdoptionsMap = {};
    if (userAdoptions.length > 0) {
      userAdoptions.reduce((prev, curr) => {
        prev[curr["Gift ID"]] = curr;
        return prev;
      }, userAdoptionsMap);
    }

    return {
      adoptedAngels: userAdoptedAngles,
      unAdoptedAngels: unAdoptedAngels,
      adoptions: userAdoptionsMap,
    };
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const userExists = async (username) => {
  try {
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[USERS_SHEET_ID];

    const userNameExists = await sheet.getRows().then((result) => {
      return result.filter((user) => user.UserName === username);
    });
    return Boolean(userNameExists.length);
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const findUser = async (username) => {
  try {
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[USERS_SHEET_ID];

    const user = await sheet.getRows().then((result) => {
      return result.filter((user) => user.UserName === username);
    });
    return user;
  } catch (e) {
    console.error("Error: ", e);
  }
};

export const getAdoptionsMap = async (userName) => {
  try {
    await doc.loadInfo();

    const adoptionSheet = doc.sheetsById[ADOPTIONS_SHEET_ID];

    const adoptions = await adoptionSheet.getRows().then((rows) => {
      return rows.filter((row) => row.UserName === userName);
    });

    const adoptionsMap = {};
    adoptions.reduce((acc, curr) => {
      acc[curr["Gift ID"]] = curr;
      return acc;
    }, adoptionsMap);

    return adoptionsMap;
  } catch (e) {
    console.error("Error: ", e);
  }
};
