import http from "./http";
import qs from "qs";
import storage from "../../utils/storage";

function path(strings) {
  return `/token${strings[0]}`;
}

async function login(username, password) {
  const params = qs.stringify({ username, password });
  const { data } = await http.post(path`/`, params);
  storage.setToken(data.accessToken);
}

function logout() {
  storage.clear();
}

export default {
  login,
  logout,
};
