import http from "./http";

function path(strings) {
  return `/reset${strings[0]}`;
}

async function createReset(data) {
  return await http.post(path`/`, data);
}

async function verifyReset(token) {
  return await http.get(path`/`, { params: { token } });
}

async function updatePassword(token, password) {
  return await http.put(path`/password`, { token, password });
}

export default {
  createReset,
  verifyReset,
  updatePassword,
};
