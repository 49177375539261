import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MesgBox from "../common/mesgBox";
import resetSrvc from "../../services/hhtree/reset";

const PasswordForm = (props) => {
  const [mesg, setMesg] = useState({});
  const [inProgress, setInProgress] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await resetSrvc.verifyReset(props.token);
      } catch (error) {
        handleResetError(error);
      }
    };
    verifyToken();
  }, [props.token]);

  const onSubmit = async ({ password }) => {
    setMesg({});
    setInProgress(true);
    try {
      await resetSrvc.updatePassword(props.token, password);
      history.replace("/login");
    } catch (error) {
      handleResetError(error);
    } finally {
      setInProgress(false);
    }
  };

  const handleResetError = (error) => {
    console.log(error);
    if (error.response) {
      const { status } = error.response;
      if (status === 404) {
        setMesg({ text: "Reset URL is invalid", type: "error" });
      } else if (status === 410) {
        setMesg({ text: "Reset URL has expired", type: "error" });
      }
    }
  };

  return (
    <div>
      <div className="text-2xl mb-3">Update Password</div>
      <MesgBox text={mesg.text} type={mesg.type} />
      <div className="flex items-center"></div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-4">
          <label htmlFor="password">Password</label>
          <input
            id="Password"
            type="password"
            {...register("password", {
              required: "Password is required",
              minLength: { value: 8, message: "Password length must be >= 8" },
              maxLength: { value: 64, message: "Too many characters" },
            })}
            autoFocus
            placeholder="New password"
          />
          <div className="error">{errors.password?.message}</div>
        </div>
        <div className="mb-4">
          <label htmlFor="passwordConfirm">Confirm Password</label>
          <input
            id="passwordConfirm"
            type="password"
            {...register("passwordConfirm", {
              required: "Password is required",
              validate: (value) => {
                const { password } = getValues();
                return password === value || "Passwords do not match";
              },
            })}
            placeholder="Re-enter password"
          />
          <div className="error">{errors.passwordConfirm?.message}</div>
        </div>
        <div>
          <button type="submit" disabled={inProgress}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordForm;
