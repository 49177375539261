import React from "react";
import Angel from "./../Angel/angel";
import Paginator from "./../Paginator/paginator";
import { paginate } from "./../../utils/paginate";
import _ from "lodash";

class AngelWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      angels: this.props.angels,
      angelPositions: [
        { top: "55%", left: "70%", used: false },
        { top: "25%", left: "55%", used: false },
        { top: "25%", left: "55%", used: false },
        { top: "15%", left: "45%", used: false },
        { top: "35%", left: "60%", used: false },
        { top: "35%", left: "45%", used: false },
        { top: "45%", left: "50%", used: false },
        { top: "65%", left: "30%", used: false },
        { top: "50%", left: "25%", used: false },
        { top: "75%", left: "35%", used: false },
        { top: "80%", left: "20%", used: false },
        { top: "80%", left: "45%", used: false },
        { top: "65%", left: "45%", used: false },
        { top: "25%", left: "45%", used: false },
        { top: "40%", left: "30%", used: false },
        { top: "70%", left: "60%", used: false },
        { top: "80%", left: "75%", used: false },
        { top: "55%", left: "45%", used: false },
        { top: "68%", left: "20%", used: false },
        { top: "68%", left: "75%", used: false },
        { top: "82%", left: "62%", used: false },
      ],
      currentPage: 1,
    };
  }

  handleNextClick = (currentPage) => {
    this.setState({
      currentPage: currentPage + 1,
    });
  };

  handlePreviousClick = (currentPage) => {
    this.setState({
      currentPage: currentPage - 1,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.angels !== this.props.angels) {
      this.setState({
        angels: this.props.angels,
      });
    }
  };

  render() {
    const currentPageAngels = paginate(
      this.state.angels,
      this.state.currentPage,
      15
    );
    return (
      <div className="w-2/5 flex relative">
        <Paginator
          onNextClick={this.handleNextClick}
          currentPage={this.state.currentPage}
          items={this.state.angels}
          onPreviousClick={this.handlePreviousClick}
        />
        {currentPageAngels.map((angel) => (
          <Angel
            key={angel["Gift ID"]}
            position={randomAngelPosition(this.state.angelPositions)}
            {...angel}
            onAngelReserved={this.props.onAngelReserved}
          />
        ))}
      </div>
    );
  }
}
function randomAngelPosition(angelPositions) {
  let result = _(angelPositions).shuffle().find(["used", false]);
  if (result) {
    result.used = true;
    return result;
  } else {
    angelPositions.map((x) => (x.used = false));
    _(angelPositions).first().used = true;
    return _(angelPositions).first();
  }
}

export default AngelWrapper;
