function clear() {
  sessionStorage.clear();
}

function getToken() {
  return sessionStorage.getItem("token");
}

function setToken(token) {
  sessionStorage.setItem("token", token);
}

function getUser(user) {
  let json = sessionStorage.getItem("user");
  return json ? JSON.parse(json) : {};
}

function setUser(user) {
  sessionStorage.setItem("user", JSON.stringify(user));
}

export default { getToken, setToken, getUser, setUser, clear };
