import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { toast } from "react-toastify";
import storage from "../../utils/storage";

axios.defaults.baseURL = process.env.REACT_APP_HHTREE_API_BASE;
axios.interceptors.request.use(requestHandler);
axios.interceptors.response.use(responseHandler, errorHandler);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
};

export const paths = {
  token: "/token/",
  authorization: new Set(),
};

function requestHandler(req) {
  if (paths.authorization.has(req.url)) {
    req.headers["Authorization"] = "Bearer " + storage.getToken();
  }
  req.data = decamelizeKeys(req.data);
  req.params = decamelizeKeys(req.params);
  return req;
}

function responseHandler(resp) {
  if (resp.headers["content-type"].endsWith("/json")) {
    resp.data = camelizeKeys(resp.data);
  }
  return resp;
}

function errorHandler(error) {
  if (error.response) {
    // response w/ status in 4xx to 5xx
    const { config, data, status } = error.response;
    if (status >= 500) {
      toast.error("Service error");
    } else {
      toast.error(data.detail);
    }
    if (status === 401 && config.url !== paths.token) {
      window.location = "/login";
    }
  } else if (error.request) {
    // request sent but no response
    toast.error("Service unavailable");
  } else {
    // request not sent
    console.log(error);
    toast.error("Unable to send request");
  }
  return Promise.reject(error);
}
