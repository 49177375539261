import _ from "lodash";

export function paginate(items, pageNumber, pageSize) {
  if (pageNumber <= 0) {
    return [];
  }
  const startIndex = (pageNumber - 1) * pageSize;
  let result = _(items).slice(startIndex).take(pageSize).value();
  return result;
}
