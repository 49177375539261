import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavBar from "./components/NavBar/navBar";
import Tree from "./components/Tree/tree";
import Logout from "./components/logout";
import Login from "./components/Login/login";
import Signup from "./components/Signup/signup";
import AdoptedAngels from "./components/AdoptedAngels/adoptedAngels";
import { initializeSheetConnection, getAngels } from "./utils/connectWithSheets";
import ResetPassword from "./components/ResetPassword/resetPassword";
import storage from "./utils/storage";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  constructor() {
    super();
    this.state = {
      angels: [],
      user: {},
      userAdoptedAngels: [],
      userAdoptions: [],
      isMobile: false,
    };
  }

  componentDidMount = () => {
    const user = storage.getUser();
    const { username, firstName, lastName, email, phone } = user;
    this.setState({
      user: {
        userName: username,
        displayName: firstName + " " + lastName,
        email,
        telephoneNumber: phone,
      },
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.angels.length === 0 && this.state.user.userName) {
      initializeSheetConnection();

      getAngels(this.state.user.userName).then((result) => {
        this.setState({
          angels: result.unAdoptedAngels,
          userAdoptedAngels: result.adoptedAngels,
          userAdoptions: result.adoptions,
        });
      });
    }
  };

  handleAdoption = (newAdoptions) => {
    const currentUserAdoptions = this.state.userAdoptedAngels;
    // added angels to the current state for adoptedAngels and subtracted from unadopted angels
    // since this would avoid reaching to sheets again and would rerender all components as well.
    let adoptedAngelIds = [];
    newAdoptions.forEach((element) => {
      adoptedAngelIds.push(element["Gift ID"]);
      currentUserAdoptions.push(Array(element));
    });

    const currentUnadoptedAngels = this.state.angels.filter(
      (angel) => !adoptedAngelIds.includes(angel["Gift ID"])
    );

    this.setState({
      angels: currentUnadoptedAngels,
      userAdoptedAngels: currentUserAdoptions,
    });
  };

  // to update App state, updating only userAdoptedAngels and not putting it back on tree
  // because the user does not want to adopt this angel,
  // the angel will back on tree in the next login or other user's login, by filtering the data in the sheet
  handleAbandonment = (abandonedAngelId) => {
    let userAdoptedAngels = [...this.state.userAdoptedAngels];
    userAdoptedAngels = userAdoptedAngels.filter(
      (angel) => angel[0]["Gift ID"] !== abandonedAngelId
    );
    this.setState({ userAdoptedAngels });
  };

  handleUserChange = (user) => {
    storage.setUser(user);
    const { username, firstName, lastName, email, phone } = user;
    this.setState({
      user: {
        userName: username,
        displayName: firstName + " " + lastName,
        email,
        telephoneNumber: phone,
      },
    });
  };

  handleGiftStatusChange = async (e, adoption) => {
    const status = adoption.OrderedDelivered === "y" ? "" : "y";
    adoption.OrderedDelivered = status;
    await adoption.save();

    const userAdoptions = { ...this.state.userAdoptions };
    userAdoptions[adoption["Gift ID"]].OrderedDelivered = status;
    this.setState({ userAdoptions });
  };

  handleAdoptionsLoad = (adoptionsMap) => {
    this.setState({ userAdoptions: adoptionsMap });
  };

  render() {
    const { user, angels, isMobile } = this.state;
    return (
      <div className="h-full flex flex-col">
        <NavBar user={user} isMobile={isMobile} />
        <div className="content h-full flex flex-col">
          <Switch>
            <Route
              path="/login"
              render={(props) => (
                <Login {...props} onUserLogin={this.handleUserChange} />
              )}
            />
            <Route
              path="/tree"
              render={(props) => (
                <Tree
                  {...props}
                  angels={angels}
                  user={user}
                  adoptionHandler={this.handleAdoption}
                  isMobile={isMobile}
                />
              )}
            />
            <Route
              path="/reservedTags"
              render={(props) => {
                if (user.userName) {
                  return (
                    <AdoptedAngels
                      {...props}
                      user={user}
                      adoptedAngels={this.state.userAdoptedAngels}
                      abandonmentHandler={this.handleAbandonment}
                      adoptions={this.state.userAdoptions}
                      onGiftStatusChange={this.handleGiftStatusChange}
                      onAdoptionsLoad={this.handleAdoptionsLoad}
                    />
                  );
                }
                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: props.location },
                    }}
                  />
                );
              }}
            />
            <Route path="/logout" component={Logout} />
            <Route
              path="/signup"
              render={(props) => (
                <Signup {...props} onUserSignUp={this.handleUserChange} />
              )}
            />
            <Route path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/"
              render={(props) => <Redirect {...props} to="/login" />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
