import { Component } from "react";

import CasClient from "react-cas-client";
import { casEndpoint, casOptions } from "./../utils/casClient";

class Logout extends Component {
  state = {
    casClient: new CasClient(casEndpoint, casOptions),
  };

  componentDidMount() {
    sessionStorage.clear();
    localStorage.clear();
    this.state.casClient.logout("/login");
  }

  componentDidUpdate() {}

  render() {
    return null;
  }
}

export default Logout;
