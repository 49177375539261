import React from "react";
import qs from "qs";
import RequestForm from "./requestForm";
import PasswordForm from "./passwordForm";

const ResetPassword = (props) => {
  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return (
    <div className="w-full mx-auto pt-12 pb-8 px-8 sm:w-96 sm:px-0">
      {params.t === undefined ? <RequestForm /> : <PasswordForm token={params.t} />}
    </div>
  );
};

export default ResetPassword;
