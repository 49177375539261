import React, { Component } from "react";
import { deleteAdoptedAngel, getAdoptionsMap } from "../../utils/connectWithSheets";

class AdoptedAngels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservedAngels: [],
      adoptedAngels: []
    };
  }

  onAngelRemoveReserved = (e, angelId) => {
    let updatedReservedAngels = [...this.state.reservedAngels];
    updatedReservedAngels = updatedReservedAngels.filter(
      (angel) => angel["Gift ID"] !== angelId
    );

    this.setState({
      reservedAngels: updatedReservedAngels,
    });

    this.props.location.onRemoveReserved.onRemoveAngelReserved(
      e,
      angelId,
      false
    );
  };

  componentDidMount = async () => {
    const adoptions = await getAdoptionsMap(this.props.user.userName);
    this.props.onAdoptionsLoad(adoptions);

    this.setState({
      reservedAngels: this.props.location.reservedAngels
        ? this.props.location.reservedAngels.reservedAngels
        : [],
        adoptedAngels : this.props.adoptedAngels
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.adoptedAngels !== this.props.adoptedAngels) {
      this.setState({
        adoptedAngels: this.props.adoptedAngels,
      });
    }
  }


  onAdoptingReservedAngels = () => {
    this.props.location.onAngelAdoption.adoptReservedAngels();
    this.setState({ reservedAngels: [] });
  };

  onUnAdopting = (angelId) => {
    deleteAdoptedAngel(this.props.user.userName, angelId)
    this.props.abandonmentHandler(angelId);
  }

  render() {
    return (
      <div className="flex justify-center my-6">
        <div className="flex flex-col w-full p-8 text-gray-800 bg-white shadow-lg pin-r pin-y md:w-4/5 lg:w-4/5">
          {this.state.reservedAngels.length > 0 && (
            <React.Fragment>
              <div className="p-4 bg-gray-100 rounded-full">
                <h1 className="ml-2 font-bold uppercase">
                  Your Selected Tags
                </h1>
              </div>
              <div className="flex-1">
                <table className="w-full text-sm lg:text-base" cellSpacing="0">
                  <thead>
                    <tr className="h-12 uppercase">
                      <th>Age</th>
                      <th className="justify-center">Gender</th>
                      <th className="justify-center">Gift Request</th>
                      <th className="justify-center">Size/Notes</th>
                      <th className="justify-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.reservedAngels.map((angel) => (
                      <tr key={angel["Gift ID"]}>
                        <td className="text-center">{angel["Age"]}</td>
                        <td className="text-center">{angel["Gender"]}</td>
                        <td className="text-center">{angel["Gift Request"]}</td>
                        <td className="text-center">{(angel["Size"]? angel["Size"] : "") + " " + (angel["Notes"]? angel["Notes"] : "")}</td>

                        <td className="text-center">
                          <svg
                            aria-hidden="true"
                            data-prefix="far"
                            data-icon="cross-alt"
                            className="w-4 text-gs-navy hover:text-blue-900 m-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            onClick={(e) =>
                              this.onAngelRemoveReserved(e, angel["Gift ID"])
                            }
                          >
                            <path
                              fill="currentColor"
                              d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"
                            />
                          </svg>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="my-4 mt-6 -mx-2 lg:flex">
                  <button
                    className="w-full"
                    onClick={this.onAdoptingReservedAngels}
                  >
                    <span className="ml-2 mt-5px">Reserve Selected Tags</span>
                  </button>
                </div>
                <hr className="pb-6 mt-6" />
              </div>
            </React.Fragment>
          )}

          {this.state.adoptedAngels.length > 0 ? (
            <div className="flex-1">
              <div className="p-4 bg-gray-100 rounded-full">
                <h1 className="ml-2 font-bold uppercase">Your Reserved Tags</h1>
              </div>
            <div className="flex-1 overflow-x-auto">
              <p className="mb-4 italic">
                Thank you for participating in this year's virtual Holiday Helper Tree!  Please contact <a href="mailto:Leader@GeorgiaSouthern.edu">Leader@GeorgiaSouthern.edu</a> if you have any questions.
              </p>
              <table className="w-full text-sm lg:text-base" cellSpacing="0">
                <thead>
                  <tr className="h-12 uppercase">
                    <th>Tag</th>
                    <th>Ordered / Delivered</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Wish Request</th>
                    <th>Size / Notes</th>
                    <th>Mailing Address</th>
                    <th>Cancel</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.adoptedAngels.map((angel) => (
                    <tr key={angel[0]["Gift ID"]} className="text-center">
                      <td>{angel[0]["Gift ID"]}</td>
                      <td>
                        <Checkbox
                          adoption={this.props.adoptions[angel[0]['Gift ID']]}
                          onChange={this.props.onGiftStatusChange}
                        />
                      </td>
                      <td>{angel[0]["Age"]}</td>
                      <td>{angel[0]["Gender"]}</td>
                      <td>{angel[0]["Gift Request"]}</td>
                      <td>{(angel[0]["Size"]? angel[0]["Size"] : "") + " " + (angel[0]["Notes"]? angel[0]["Notes"] : "")}</td>

                      <td><address>{angel[0]["Address"]}</address></td>
                      <td>
                        <svg
                          aria-hidden="true"
                          data-prefix="far"
                          data-icon="trash-alt"
                          className="w-4 text-gs-navy hover:text-blue-900 m-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          onClick={() => this.onUnAdopting(angel[0]["Gift ID"])}
                          role="button"
                          tabIndex="0"
                          aria-label="Remove Reserved"
                          onKeyUp={(ev) => { (ev.key === 'Enter') && this.onUnAdopting(angel[0]["Gift ID"])}}
                        >
                          <path
                            fill="currentColor"
                            d="M268 416h24a12 12 0 0012-12V188a12 12 0 00-12-12h-24a12 12 0 00-12 12v216a12 12 0 0012 12zM432 80h-82.41l-34-56.7A48 48 0 00274.41 0H173.59a48 48 0 00-41.16 23.3L98.41 80H16A16 16 0 000 96v16a16 16 0 0016 16h16v336a48 48 0 0048 48h288a48 48 0 0048-48V128h16a16 16 0 0016-16V96a16 16 0 00-16-16zM171.84 50.91A6 6 0 01177 48h94a6 6 0 015.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0012-12V188a12 12 0 00-12-12h-24a12 12 0 00-12 12v216a12 12 0 0012 12z"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr className="pb-6 mt-6" />
            </div>
          </div>
          ) : (
          <>
            <p  className="mb-4 italic">You do not have any reserved tags.</p>
            <hr className="pb-6 mt-6" />
          </>
          )}
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Instructions for gift-givers</h1>
            </div>
            <div className="flex-1">
              <ul className="p-4 mb-4 italic list-disc">
                <li>Suggested gift card amount is $30</li>
                <li>Gifts can be shipped wrapped or unwrapped
                  <ul className="px-4 list-disc">
                    <li>
                      Gifts may be delivered in-person to agencies, but we recommend contacting the agencies
                      directly prior to delivery to ensure someone is available to receive the package. All
                      organizations are listed on our <a href="https://www.georgiasouthern.edu/students/LeadServe/overview/" target="_blank" rel="noopener noreferrer">Community Partner Website</a>
                      under the County in which they are housed.
                    </li>
                  </ul>
                </li>
                <li>
                  Gifts must be mailed or delivered by December 6th. Remember to check the box under your Reserved Tags to
                  indicate that it has been ordered/delivered.
                </li>
                <li>The tag ID should be included in the shipping address. See examples below.</li>
              </ul>
            </div>
            <hr className="pb-6 mt-6" />
          </div>
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Shipping Address Examples</h1>
            </div>
            <div className="flex-1 pt-3">
              <div className="mb-3">
                <div className="mb-2 text-lg">If mailing address <b>does not</b> contain an "Attention" person</div>
                <div className="pl-3">
                  <div><i>Agency / Organization Name</i></div>
                  <div>Attn: <i>TagID</i></div>
                  <div><i>Address Line 1</i></div>
                  <div><i>Address Line 2</i></div>
                  <div><i>City, State Zip</i></div>
                </div>
              </div>
              <div>
                <div className="mb-2 text-lg">If mailing address contains an "Attention" person</div>
                <div className="pl-3">
                  <div><i>Agency / Organization Name</i></div>
                  <div>Attn: <i>FirstName LastName</i> <i>(TagID)</i></div>
                  <div><i>Address Line 1</i></div>
                  <div><i>Address Line 2</i></div>
                  <div><i>City, State Zip</i></div>
                </div>
              </div>
            </div>
            <hr className="pb-6 mt-6" />
          </div>
        </div>
      </div>
    );
  }
}

export default AdoptedAngels;

function Checkbox(props) {
  return (
    props.adoption === undefined ? (
      <input type="checkbox" disabled={true} />
    ) : (
      <input
        type="checkbox"
        onChange={(e) => props.onChange(e, props.adoption)}
        checked={props.adoption.OrderedDelivered === "y"}
      />
    )
  );
}
