import React, { useEffect } from "react";
import qs from "qs";
import GSLogin from "./gsLogin";
import NonGSLogin from "./nonGSLogin";
import storage from "../../utils/storage";

const Login = (props) => {
  useEffect(() => {
    const user = storage.getUser();
    if (Object.keys(user).length > 0) {
      props.onUserLogin(user);
      props.history.replace("/tree");
    }
  });

  const { ticket } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  return (
    <div className="login-page sm:flex-row sm:justify-end">
      {ticket ? (
        <div className="login-wrap">
          <GSLogin {...props} ticket={ticket} />
        </div>
      ) : (
        <div className="ml-0 sm:mr-12">
          <div className="login-wrap mb-5">
            <GSLogin {...props} />
          </div>
          <div className="login-wrap">
            <NonGSLogin {...props} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
