import React from "react";

const Select = ({
  name,
  label,
  options,
  error,
  divStyleClass = "",
  ...rest
}) => {
  return (
    <div className={"flex flex-row relative " + divStyleClass}>
      <label htmlFor={name} className="pl-4 pr-8 m-auto">
        {label}
      </label>
      <select name={name} id={name} {...rest}>
        <option value="" />
        {options.map((option) => (
          <option key={option.id} value={option.id} {...option}>
            {option.name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
      {error && <p className="text-red-500 text-xs italic">{error}.</p>}
    </div>
  );
};

export default Select;
