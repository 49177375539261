import React from "react";
import { paginate } from "./../../utils/paginate";

const Paginator = (props) => {
  return (
    <React.Fragment>
      {paginate(props.items, props.currentPage + 1, 15).length > 0 && (
        <i
          className="fas fa-arrow-right"
          style={{
            fontSize: "64px",
            color: "#E40A2D",
            position: "absolute",
            top: "50%",
            left: "80%",
          }}
          onClick={() => {
            props.onNextClick(props.currentPage);
          }}
          onKeyUp={(ev) => {
            ev.key === "Enter" && props.onNextClick(props.currentPage);
          }}
          role="button"
          tabIndex="0"
          alt="Next"
        />
      )}
      {props.currentPage - 1 >= 1 && (
        <i
          className="fas fa-arrow-left"
          style={{
            fontSize: "64px",
            color: "#E40A2D",
            position: "absolute",
            top: "50%",
            left: "2%",
          }}
          onClick={() => {
            props.onPreviousClick(props.currentPage);
          }}
          onKeyUp={(ev) => {
            ev.key === "Enter" && props.onPreviousClick(props.currentPage);
          }}
          role="button"
          tabIndex="0"
          alt="Previous"
        />
      )}
    </React.Fragment>
  );
};

export default Paginator;
