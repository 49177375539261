import React, { Component } from "react";
import AngelWrapper from "./../AngelWrapper/angelWrapper";
import FilterAngelForm from "./../FilterAngelForm/filterAngelForm";

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      angels: this.props.angels,
      minage: "",
      maxage: "",
      gender: "",
    };
  }

  componentDidMount() {
    if(window.innerHeight > window.innerWidth){
      alert("Please use Landscape!");
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.angels !== this.props.angels) {
      this.setState({
        angels: this.props.angels,
      });
    }
  };

  handleAngelReservation = (e, angelId, reserve) => {
    let updatedAngels = [...this.state.angels];
    updatedAngels = updatedAngels.map((angel) => {
      if (angel["Gift ID"] === angelId) {
        angel["Reserved"] = reserve;
        return angel;
      }

      return angel;
    });

    this.setState({
      angels: updatedAngels,
    });
  };

  handleSelectChange = ({ currentTarget: input }) => {
    if (input.name === "gender") this.setState({ gender: input.value });
    if (input.name === "age") {
      this.setState({
        minage: input.options[input.selectedIndex].getAttribute("minage"),
        maxage: input.options[input.selectedIndex].getAttribute("maxage"),
      });
    }
  };

  filterAngelsByAge = (angels) => {
    let result = [];
    if (this.state.minage) {
      result = angels.filter((angel) => {
        return (
          Number(angel["Age"]) >= Number(this.state.minage) &&
          Number(angel["Age"] <= Number(this.state.maxage))
        );
      });
    } else {
      result = angels;
    }
    return result;
  };

  filterAngelsByGender = (angels) => {
    let result = [];
    if (this.state.gender) {
      result = angels.filter((angel) => {
        return angel["Gender"] === this.state.gender;
      });
    } else {
      result = angels;
    }
    return result;
  };

  getFilteredAngels = () => {
    let filteredAngels = this.filterAngelsByAge(this.state.angels);
    filteredAngels = this.filterAngelsByGender(filteredAngels);
    return filteredAngels.filter((angel) => angel["Reserved"] !== true);
  };

  render() {
    return (
      <div className="h-full background-image flex flex-row">
        <div className="w-3/5">
          <FilterAngelForm
            angels={this.state.angels}
            onRemoveAngelReserved={this.handleAngelReservation}
            onDropDownChange={this.filterAngels}
            onSelectChange={this.handleSelectChange}
            gender={this.state.gender}
            age={this.state.minage}
            user={this.props.user}
            onAngelAdoption={this.props.adoptionHandler}
            isMobile={this.props.isMobile}
          />
        </div>
        <AngelWrapper
          angels={this.getFilteredAngels()}
          onAngelReserved={this.handleAngelReservation}
        />
      </div>
    );
  }
}

export default Tree;
