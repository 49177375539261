import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "./logo.png";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      isMobile: Boolean(this.props.isMobile),
      showNavMenu: !this.props.isMobile,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user,
        isMobile: this.props.isMobile,
        showNavMenu: !this.props.isMobile,
      });
    }
  };

  toggleMenuView = () => {
    this.setState({ showNavMenu: !this.state.showNavMenu });
  };

  render() {
    const { user, showNavMenu } = this.state;
    return (
      <nav className="flex items-center justify-between flex-wrap bg-gs-navy p-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <img src={logo} alt="Georgia Southern University logo" width="250" />
          <span className="pl-5 font-semibold text-xl tracking-tight">
            Holiday Helper Tree
          </span>
        </div>
        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
            onClick={this.toggleMenuView}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        {showNavMenu && (
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="text-sm lg:flex-grow">
              {!user.userName && (
                <NavLink
                  to="/login"
                  className="navlink"
                  activeClassName="navLinkActive"
                >
                  Login
                </NavLink>
              )}
              {user.userName && (
                <React.Fragment>
                  <NavLink
                    to="/tree"
                    className="navlink"
                    activeClassName="navLinkActive"
                  >
                    Tree
                  </NavLink>
                  <NavLink
                    to="/reservedTags"
                    className="navlink"
                    activeClassName="navLinkActive"
                  >
                    Reserved Tags
                  </NavLink>
                  <NavLink
                    to="/logout"
                    className="navlink"
                    activeClassName="navLinkActive"
                  >
                    Logout
                  </NavLink>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </nav>
    );
  }
}

export default NavBar;
