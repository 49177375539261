import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useHistory } from "react-router-dom";
import authSrvc from "../../services/hhtree/auth";
import usersSrvc from "../../services/hhtree/users";

const NonGSLogin = (props) => {
  const [error, setError] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const onSubmit = async ({ username, password }) => {
    setError("");
    setInProgress(true);
    try {
      await authSrvc.login(username, password);
      const { data: user } = await usersSrvc.getMe();
      props.onUserLogin(user);
      history.replace("/tree");
    } catch (error) {
      console.log(error);
    } finally {
      setInProgress(false);
    }
  };

  return (
    <>
      <div className="text-2xl mb-3">Non-GS Users</div>
      {error && <div className="my-3 text-center text-red-600">{error}</div>}
      <div className="flex items-center"></div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-4">
          <label className="block font-bold text-sm mb-1" htmlFor="username">
            Email
          </label>
          <input
            className="appearance-none border border-gray-400 rounded w-full px-2 py-2 focus:outline-none focus:shadow-outline"
            id="username"
            type="email"
            {...register("username", {
              required: "Email is required",
              pattern: { value: /\w+@\w+/, message: "Invalid email address" },
            })}
            autoFocus
            placeholder="Email address"
          />
          {errors.email && <div className="error">{errors.email.message}</div>}
        </div>
        <div className="mb-4">
          <label className="block font-bold text-sm mb-1" htmlFor="password">
            Password
          </label>
          <input
            className="appearance-none border border-gray-400 rounded w-full px-2 py-2 focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            {...register("password", { required: "Password is required" })}
            placeholder="************"
          />
          {errors.password && <div className="error">{errors.password.message}</div>}
        </div>
        <div className="flex items-center justify-between">
          <button type="submit" disabled={inProgress}>
            Log in
          </button>
          <NavLink to="/reset-password" className="text-sm">
            Forgot password?
          </NavLink>
        </div>
        <div className="mt-5 text-center">
          New user?&nbsp;
          <a className="text-sm" href="/signup">
            Create an account
          </a>
        </div>
      </form>
    </>
  );
};

export default NonGSLogin;
