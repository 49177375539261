import React from "react";

const Input = ({
  name,
  label,
  error,
  divStyleClass = "",
  inputStyleClass = "",
  ...rest
}) => {
  return (
    <div className={"mb-4 px-3 " + divStyleClass}>
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id={name}
        type="text"
        placeholder={label}
        name={name}
        {...rest}
      />
      {error && <p className="text-red-500 text-xs italic">{error}.</p>}
    </div>
  );
};

export default Input;
