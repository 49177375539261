import React, { Component } from "react";
import CasClient from "react-cas-client";
import { casEndpoint, casOptions } from "./../../utils/casClient";

class GSLogin extends Component {
  state = {
    casClient: new CasClient(casEndpoint, casOptions),
  };

  componentDidMount() {
    // validate CAS ticket
    if (this.props.ticket) {
      this.state.casClient
        .auth(false, true)
        .then((successRes) => {
          const { user, displayName, mail, telephoneNumber } = successRes;
          const [firstName, lastName] = displayName.split(" ");
          this.props.onUserLogin({
            username: user,
            firstName,
            lastName,
            email: mail,
            phone: telephoneNumber,
            isCAS: true,
          });
          this.props.history.replace("/tree");
        })
        .catch((errorRes) => {
          console.error(errorRes);
        });
    }
  }

  doSubmit = async () => {
    // use casClient to authenticate
    this.state.casClient
      .auth(false, true)
      .then((successRes) => {
        console.log(successRes);
      })
      .catch((errorRes) => {
        console.error(errorRes);
      });
  };

  render() {
    return this.props.ticket ? (
      <div>Loading...</div>
    ) : (
      <>
        <div className="text-2xl mb-3">GS Users</div>
        <div className="flex items-center justify-center">
          <button onClick={() => this.doSubmit()}>
            <span>Log in</span>
          </button>
        </div>
      </>
    );
  }
}

export default GSLogin;
