import http, { paths } from "./http";

paths.authorization.add(path`/me`);

function path(strings) {
  return `/users${strings[0]}`;
}

async function getMe() {
  return await http.get(path`/me`);
}

async function signUp(user) {
  return await http.post(path`/`, user);
}

export default {
  getMe,
  signUp,
};
