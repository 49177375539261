import React, { useState, useRef } from "react";
import AngelImage from "./eGSlogo-Eagle Head_rev.png";
import { usePopper } from "react-popper";

const Angel = (props) => {
  const { top, left } = props.position;
  const [showPopper, setShowPopper] = useState(false);
  const buttonRef = useRef(null);
  const popperRef = useRef(null);

  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,

    {
      placement: "right",
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );

  return (
    <React.Fragment>
      <img
        src={AngelImage}
        className="angel"
        style={{
          top: top,
          left: left,
        }}
        ref={buttonRef}
        onClick={() => setShowPopper(!showPopper)}
        onKeyUp={(ev) => { (ev.key === 'Enter') && setShowPopper(!showPopper)}}
        aria-describedby="tooltip"
        role="button"
        tabIndex="0"
        aria-label="Tag"
        alt="Tag"
      />
      {showPopper ? (
        <div
          className="popover-id"
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
          role="tooltip"
        >
          <div>
            <div className="popover-title text-lg">Tag: {props["Gift ID"]}</div>
            <div className="text-white p-3 table">
              <table className="table-auto">
                <tbody>
                  {props.Gender ? (
                  <tr>
                    <th className="px-2 py-1">Gender</th>
                    <td className="px-2 py-1">{props.Gender}</td>
                  </tr>
                  ) : null}
                  {props.Age ? (
                  <tr>
                    <th className="px-2 py-1">Age</th>
                    <td className="px-2 py-1">{props.Age}</td>
                  </tr>
                  ) : null}
                  <tr>
                    <th className="px-2 py-1">Gift Request</th>
                    <td className="px-2 py-1">{props["Gift Request"]}</td>
                  </tr>
                  {props.Size || props.Notes ? (
                    <tr>
                      <th className="px-2 py-1">Size/Notes</th>
                      <td className="px-2 py-1">{(props.Size? props.Size : "") + " " + (props.Notes? props.Notes : "") }</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <button className="m-auto mt-1"
                onClick={(e) =>
                  props.onAngelReserved(e, props["Gift ID"], true)
                }
              >
                <span className="ml-2 mt-5px">Select this tag</span>
              </button>
            </div>
          </div>
          <div ref={setArrowElement} style={styles.arrow} className="arrow" />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Angel;
