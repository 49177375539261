import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import usersSrvc from "../../services/hhtree/users";

const Signup = (props) => {
  const [error, setError] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const onSubmit = async (data) => {
    setError("");
    setInProgress(true);
    try {
      data.username = data.email;
      const { data: user } = await usersSrvc.signUp(data);
      props.onUserSignUp(user);
      history.replace("/tree");
    } catch (error) {
      console.log(error);
      if (error.response?.status === 409) {
        setError("User already exists.");
      }
    } finally {
      setInProgress(false);
    }
  };

  return (
    <div className="w-full mx-auto pt-12 pb-8 px-8 sm:w-96 sm:px-0">
      <h1>Create an Account</h1>
      <div className="flex items-center"></div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-4">
          <label htmlFor="firstName">First name</label>
          <input
            id="firstName"
            type="text"
            {...register("firstName", {
              required: "First name is required",
              maxLength: { value: 64, message: "Too many characters" },
            })}
            autoFocus
            placeholder="Your first name"
          />
          {errors.firstName && <div className="error">{errors.firstName.message}</div>}
        </div>
        <div className="mb-4">
          <label htmlFor="lastName">Last name</label>
          <input
            id="lastName"
            type="text"
            {...register("lastName", {
              required: "last name is required",
              maxLength: { value: 64, message: "Too many characters" },
            })}
            placeholder="Your last name"
          />
          {errors.lastName && <div className="error">{errors.lastName.message}</div>}
        </div>
        <div className="mb-4">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            {...register("email", {
              required: "Email is required",
              maxLength: { value: 64, message: "Too many characters" },
              pattern: { value: /\w+@\w+/, message: "Invalid email format" },
            })}
            placeholder="You email address"
          />
          {errors.email && <div className="error">{errors.email.message}</div>}
        </div>
        <div className="mb-4">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            {...register("password", {
              required: "Password is required",
              minLength: { value: 8, message: "Password length must be >= 8" },
              maxLength: { value: 64, message: "Too many characters" },
            })}
            placeholder="Create your password"
          />
          {errors.password && <div className="error">{errors.password.message}</div>}
        </div>
        <div className="mb-4">
          <label htmlFor="password">Phone</label>
          <input
            id="phone"
            type="text"
            {...register("phone", {
              minLength: { value: 10, message: "Too few characters" },
              maxLength: { value: 32, message: "Too many characters" },
            })}
            placeholder="Your phone number"
          />
          {errors.phone && <div className="error">{errors.phone.message}</div>}
        </div>
        <div>
          <button type="submit" disabled={inProgress} className="w-full">
            Sign up
          </button>
          {error && <div className="mt-3 text-center text-red-600">{error}</div>}
        </div>
      </form>
    </div>
  );
};

export default Signup;
