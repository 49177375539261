import React, { useState } from "react";
import { useForm } from "react-hook-form";
import resetSrvc from "../../services/hhtree/reset";
import MesgBox from "../common/mesgBox";

const RequestForm = (props) => {
  const [mesg, setMesg] = useState({ text: "", type: "info" });
  const [inProgress, setInProgress] = useState(false);

  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;

  const onSubmit = async (data) => {
    setMesg({});
    setInProgress(true);
    try {
      await resetSrvc.createReset(data);
      setMesg({
        text: `An email was sent to ${data.username} if that user exists.`,
      });
    } catch ({ response }) {
      if (!response) return;
    } finally {
      setInProgress(false);
    }
  };

  return (
    <div>
      <div className="text-2xl mb-3">Request Password Reset</div>
      <MesgBox text={mesg.text} type={mesg.type} />
      <div className="flex items-center"></div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-4">
          <label htmlFor="username">Email</label>
          <input
            id="username"
            type="email"
            {...register("username", {
              required: "Email is required",
              pattern: { value: /\w+@\w+/, message: "Invalid email address" },
            })}
            autoFocus
            placeholder="Email address"
          />
          {errors.username && <div className="error">{errors.username.message}</div>}
        </div>
        <div>
          <button type="submit" disabled={inProgress}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default RequestForm;
