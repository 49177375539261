import React from "react";

export default function MesgBox({ text, type }) {
  if (!text) return null;
  let styles = "";
  switch (type) {
    case "error":
      styles = "bg-red-100 text-red-900";
      break;
    default:
      styles = "bg-blue-100 text-blue-900";
      break;
  }
  return <div className={["my-3 p-3", styles].join(" ")}>{text}</div>;
}
